import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import EmailForm from "../components/email-form";
import {
  GiFireplace,
  GiKeyLock,
  GiSteam,
  GiSuitcase,
  GiTrashCan,
  GiTvRemote,
} from "react-icons/gi";
import autoFan from "../images/hvac/autofan.webp";
const h1ClassNames = "text-center font-bold text-4xl pb-5";
const h2ClassNames = "italic font-bold text-3xl pt-4";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-xl";

export function Head() {
  return (
    <>
      <title>Overlook Chalet</title>
      <description>Checkout Instructions</description>
    </>
  );
}

const GuestsPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <GiSuitcase className="m-auto text-4xl" />
          <h1 className={h1ClassNames}>Checkout Instructions</h1>

          <h2 className={h2ClassNames}>
            Quick cleanup
            <GiTrashCan className="m-auto text-3xl" />
          </h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Ensure all dishes, pots, pans, and any kitchen appliances used are
              washed. Please leave the lid to the coffee maker open. Start the
              dishwasher when you depart, please.
            </li>
            <li className={liClassNames}>
              Remove all trash from counters, tables, etc. Tie up all trash and
              recycling bags securely and place them in the containers outside.
              Please make sure the containers latch securely to prevent local
              wildlife from enjoying your leftovers.&#128063;
            </li>
            <li className={liClassNames}>
              Clean out the refrigerator and freezer of any items you brought
              and would like to take home with you.
            </li>
            <li className={liClassNames}>
              Place used towels and washcloths in the bathtubs (or shower for
              the bathroom upstairs).
            </li>
          </ol>
          <h2 className={h2ClassNames}>
            Heating and air <GiFireplace className="m-auto text-3xl" />
          </h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Please turn the pellet stove completely off.
            </li>
            <li className={liClassNames}>
              Leave all Mysa<sup>®</sup> thermostats on 61&deg;F set to heat
              mode, fan set to automatic.{" "}
              <img className="inline" width="50px" src={autoFan} />
            </li>
            <li className={liClassNames}>Leave the living room fan on low.</li>
          </ol>
          <h2 className={h2ClassNames}>
            Hot tub
            <GiSteam className="m-auto text-3xl" />
          </h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Make sure the hot tub is set to 34&deg;C, covered and the{" "}
              <b>straps are securely in place.</b>
            </li>
          </ol>
          <h2 className={h2ClassNames}>
            Suggested odds & ends <GiTvRemote className="m-auto text-3xl" />
          </h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>Sign out of any streaming services</li>
            <li className={liClassNames}>
              Turn off all lights, fans and sound machines and close/lock all
              windows
            </li>
          </ol>
          <h2 className={h2ClassNames}>
            Lock up <GiKeyLock className="m-auto text-3xl" />
          </h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Please make sure the windows are locked!
            </li>
            <li className={liClassNames}>Lock the basement door</li>
            <li className={liClassNames}>
              Lock the side door by touching the 'Yale' symbol
            </li>
          </ol>

          <div id="mailing-list">
            <EmailForm />
          </div>

          <h2 className={h2ClassNames + " text-center text-blue-800"}>
            <a href="/rental-agreement-final.pdf" target="_blank">
              Rental Agreement
            </a>
          </h2>
          <a href="/privacy-security">Privacy policy</a>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default GuestsPage;
